#demo_main_container {
    height: 100%;
}

#demo_banner {
    height: 65%;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
}

.restaurant_logo {
    text-align: center;
    width: 100%;
    min-width: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.phoneImage {
    display: block;
    width: 100%;
    max-width: 450px;
    height: auto;
    padding: 20px;
}

#storename-section {
    background-color: #000000;
}

.storeName-container {
    background-color: rgb(20,49,87);
    text-align: center;
    font-size: 21px;
    border-bottom: solid white 10px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.storeName {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none
}

.storeTagline {
    font-size: 14px;
    color: #e3e1e1;
    padding: 5px 0;
}


#white-space-section {
    background-color: #000000;
    height: 15px;
}

.white-space {
    background-color: #ffffff;
    border-radius: 0 0 70% 70%;
    height: 15px;
}

#details-section {
    background: #000;
    padding: 20px;
}

#rate_invoice {
    padding-top: 20px;
}

.ordered_items_list {
    color: #fff;
}

.ordered_item {
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    padding: 1%;
}

.quantity {
    padding: 2px 7px;
    color: #fff;
    background-color: #808080;
    border-radius: 5px;
}

#details {
    width: 60%;
    margin: 0 auto;
    height: 55%;
}

.rate_invoice_btns {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
}

.email_invoice, .rate_us {
    padding: 1em 15%;
    background: black;
    border: 1px solid #42f492;
    border-radius: 5px;
    color: #fff;
    width: 50%;
    margin: 1%;
    cursor: pointer;
    transition: .4s;
}

.invoice_details {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 5px 0;
}

.invoice_description {
    font-weight: bold;
    display: block;
    width: 50%;
    text-align: right;
}

.invoice_total {
    display: block;
    width: 50%;
    text-align: right;
    padding-right: 2%
}

.invoice {
    width: 50%;
}

.invoice_number, .table_number, .tax {
    display: flex;
    border-right: 1px solid #fff;
    padding: 0 10px 5px 0px;
}

.total_price {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loyalty_payNow_btns {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #fff;
}

#loyalty_link {
    text-decoration: none;
    color: #fff;
}

#rateus_link {
    text-decoration: none;
    color: #fff;
}

.pay_now, .loyalty {
    margin: 1%;
    padding: 1em 15%;
    background: black;
    border: 1px solid #42f492;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: .4s;
}

    .email_invoice:hover, .rate_us:hover, .pay_now:hover, .loyalty:hover {
        background-color: #c0d62e;
    }

#email_invoice_modal {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000075;
    animation: slideIn .5s;
}

@keyframes slideIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#closedModal {
    display: none;
}

.email_invoice_container {
    width: 50%;
    max-width: 600px;
    height: 60%;
    max-height: 490px;
    padding: 2%;
    background: #143157;
}

.invoice_heading {
    color: #fff;
    padding: 2%;
    text-align: center;
}

#invoice_form {
    width: 95%;
    height: 85%;
    margin: 0 auto;
    padding: 5%;
    box-shadow: 0 0 13px 3px rgb(0 0 0 / 50%);
}

.email_address, .message {
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    padding: 1%;
    border-radius: 4px;
    outline: none;
    font-size: 20px;
}

.email_address {
    margin: 0 0 20px 0;
    height: 38px;
}

.message {
    margin: 15px 0 15px 0;
    height: 40%;
    font-size: 18px;
}

.messageRate {
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    padding: 1%;
    border-radius: 4px;
    outline: none;
    margin: 15px 0 15px 0;
    height: 20%;
    width: 100%;
    font-size: 20px;
}

    .email_address::placeholder, .message::placeholder, .messageRate::placeholder {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 15px;
    }

.form_submission {
    border-top: 1px solid #fff;
    padding: 4%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 20%;
}

.submit {
    background: #42f492;
    width: 45%;
    border-radius: 5px;
    padding: 2%;
    cursor: pointer;
}


#rate_us_modal {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000075;
    animation: slideIn .5s;
}

.rate_us_container {
    width: 55%;
    max-width: 600px;
    height: 90%;
    padding: 2%;
    background: #143157;
}

.rate_us_heading, .Rate_Us_text {
    color: #fff;
    padding: 2%;
    text-align: center;
}

#rate_us_form {
    width: 95%;
    height: 85%;
    margin: 0 auto;
    padding: 5%;
    box-shadow: 0 0 13px 3px rgb(0 0 0 / 50%);
}

#rate_list {
    height: 10%;
    display: flex;
    justify-content: space-between;
}

.star {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.fa-star {
    display: flex;
    flex-direction: column;
}

.star_name {
    font-size: 13px;
}

.reviewerName, .reviewerLName, .cellphone {
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    padding: 1%;
    border-radius: 4px;
    margin-bottom: 1.2%;
    outline: none;
    height: 8%;
    font-size: 20px;
}

    .reviewerName::placeholder, .reviewerLName::placeholder, .cellphone::placeholder {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
    }

    .reviewerName:focus, .reviewerLName:focus, .cellphone:focus, .email_address:focus {
        background-color: rgba(255, 255, 255, 0.7);
    }


footer {
    background: #000;
    border-top: 1px solid #fff;
    padding: 10px;
}

.tel, .date {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    text-decoration: none
}

#pay_now_modal {
    position: fixed;
    top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000075;
    animation: slideIn .5s;
}

.pay_container {
    width: 55%;
    max-width: 600px;
    height: 90%;
    padding: 2%;
    background: #143157;
}

.pay_heading {
    color: #fff;
    text-align: center;
    padding: 2%;
    margin: 30px 0 10px 0;
    font-size: 25px;
}

#pay_options {
    height: 75%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.pay-img-container {
    width: 100%;
    height: 45%;
    margin: 8% auto;
    background: #173966;
    border-radius: 5px;
    list-style-type: none;
}

.payImg {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
    text-align: center;
    font-size: 20px;
    color: initial;
    margin: 1%;
    padding: 1em 15%;
    background: #42f492;
    border: 1px solid #42f492;
    border-radius: 5px;
    cursor: pointer;
    transition: .4s;
    width: 100%;
    display: block;
    object-fit: contain;
}

.cancelPay {
    width: 30%;
    padding: 1%;
    border-radius: 5px;
    background: #42f492;
    border: 1px solid #253737;
    float: right;
    margin: 6% 5%;
    height: 34px;
    cursor: pointer;
}


#success_modal {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000075;
    animation: slideIn .5s;
}

.success_modal_container {
    width: 40%;
    max-width: 478px;
    height: 50%;
    padding: 2%;
    background: #fff;
    border-radius: 5px;
    position: relative;
}


.tick {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
    margin: 20px auto;
}

.success_ring {
    padding: 5%;
    border: 4px solid hsla(98,55%,69%,.2);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fa-check, .fa-circle-exclamation {
    color: #a5dc86;
    transform: scale(1.6);
}

.thanks_heading {
    text-align: center;
    color: rgba(0,0,0,.65);
    font-weight: 600;
    padding: 13px 16px;
    font-size: 27px;
}

.thanks_message {
}

.thanks_btn {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 5% 5% 0;
    background-color: #7cd1f9;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 24px;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .email_invoice_container {
        width: 90%;
        max-width: 600px;
        height: 60%;
        max-height: 490px;
        padding: 2%;
        background: #143157;
    }

    .rate_us_container {
        width: 90%;
        max-width: 600px;
        height: 90%;
        padding: 2%;
        background: #143157;
    }

    .pay_container {
        width: 90%;
        max-width: 600px;
        height: 70%;
        padding: 2%;
        background: #143157;
    }

    .rate_invoice_btns {
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;
    }

    .invoice_details {
        width: 95%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 5px 0;
    }

    .loyalty_payNow_btns {
        width: 95%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        color: #fff;
    }
}
